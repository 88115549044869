var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"disabled":_vm.seveLoading}},[_c('v-toolbar',{staticClass:"pa-2",attrs:{"flat":"","dense":"","elevation":"0"}},[_c('v-toolbar-title',[_vm._v("卡機特殊費率設定")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.value.name)+" ")]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{directives:[{name:"permission-without-city",rawName:"v-permission-without-city",value:(['keelung', 'changhua']),expression:"['keelung', 'changhua']"}],attrs:{"label":"特殊費率設定","rules":[
          function (v) { return !!v || '必填'; },
          function (v) {
            return Number(v).toString() === String(v) || '格式不符'
          },
          function (v) {
            return (
              (Number(v) >= 0.01 && Number(v) <= 99.99) ||
              '需介於 0.01 ~ 99.99'
            )
          },

          function (v) {
            return (
              _vm.numeral(v).format('0.[00]') === String(v) || "小數點最多2位數"
            )
          }
        ]},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=$$v},expression:"rate"}}),_c('v-text-field',{directives:[{name:"permission-with-city",rawName:"v-permission-with-city",value:(['keelung', 'changhua']),expression:"['keelung', 'changhua']"}],attrs:{"label":"特殊費率設定","rules":[
          function (v) { return !!v || '必填'; },
          function (v) {
            return Number(v).toString() === String(v) || '格式不符'
          },
          function (v) {
            return (
              (Number(v) >= 0.1 && Number(v) <= 63.9) || '需介於 0.1 ~ 63.9'
            )
          },
          function (v) {
            return (
              _vm.numeral(v).format('0.[0]') === String(v) || "小數點最多1位數"
            )
          }
        ]},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=$$v},expression:"rate"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":!_vm.seveLoading ? 'primary' : 'grey',"loading":_vm.seveLoading,"disabled":!_vm.valid || !_vm.rate},on:{"click":function($event){return _vm.seve()}}},[_vm._v(" 儲存 ")]),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }