import settings from '@/settings'

/**
 * 學校基本資訊
 */
class BranchSchool {
  /**
   * 取得基本資訊
   */
  index(id) {
    return axios.get(settings.api.fullPath + `/ems/profile/school/${id}`)
  }
  /**
   * 更新基本資訊
   */
  update(id, params) {
    return axios.put(
      settings.api.fullPath + `/ems/profile/school/${id}`,
      params
    )
  }
  /**
   * 查詢學校班級名稱
   */
  getClassName(schoolId, params) {
    if (!params) {
      params = {}
    }
    params.schoolId = schoolId
    return axios.get(
      settings.api.fullPath + `/ems/profile/school/chgclassname`,
      {
        params: params
      }
    )
  }
  /**
   * 查詢學校班級名稱
   */
  updateClassName(schoolId, classes, isTemporaryUpdate = true) {
    const data = {
      schoolId: schoolId,
      classes: classes,
      isTemporaryUpdate: isTemporaryUpdate
    }
    return axios.put(
      settings.api.fullPath + `/ems/profile/school/chgclassname`,
      data
    )
  }

  /**
   * 學校班級國教署教室BSID對應表下載
   */
  downloadBsid() {
    return axios.get(`${settings.api.fullPath}/ems/profile/k12eaId/download`, {
      responseType: 'blob'
    })
  }

  /**
   * 匯入學校班級國教署教室BSID對應表
   */
  uploadBsid(formData) {
    return axios.post(
      `${settings.api.fullPath}/ems/profile/k12eaId/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
}

var branchSchool = new BranchSchool()
export default branchSchool
