<template>
  <div>
    <div class="pa-3">
      <v-container fluid>
        <v-expansion-panels flat v-model="panel">
          <v-expansion-panel
            v-for="item in ClassCards"
            :key="item.id"
            class="ems-opacity-50-bg frosted-glass"
          >
            <v-expansion-panel-header class="primary--text font-weight-bold">
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <cardReader v-if="item" :deviceId="item.id" :device="item" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardReader from '@/api/ems/CardReader'
import cardReader from '@/components/CardReader'

export default {
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  components: {
    cardReader
  },
  data() {
    return {
      panel: 0,
      ClassCards: null
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      typeOption: 'card/typeOption',
      statusOption: 'card/statusOption',
      relayStatusOption: 'ac/relayStatusOption'
    })
  },
  methods: {
    showName(value, options) {
      if (!options || options.length === 0) {
        return null
      }
      const foundItem = options.find((item) => item.value === value)
      return foundItem && foundItem.name ? foundItem.name : null
    },
    async getClassCards() {
      try {
        const { data } = await CardReader.roomCards(this.tagId)
        this.ClassCards = data || []
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.getClassCards()
  }
}
</script>

<style></style>
