<template>
  <v-card :disabled="seveLoading">
    <v-toolbar class="pa-2" flat dense elevation="0">
      <v-toolbar-title>卡機特殊費率設定</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-subtitle> {{ value.name }} </v-card-subtitle>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text>
        <v-text-field
          v-permission-without-city="['keelung', 'changhua']"
          v-model="rate"
          label="特殊費率設定"
          :rules="[
            (v) => !!v || '必填',
            (v) => {
              return Number(v).toString() === String(v) || '格式不符'
            },
            (v) => {
              return (
                (Number(v) >= 0.01 && Number(v) <= 99.99) ||
                '需介於 0.01 ~ 99.99'
              )
            },

            (v) => {
              return (
                numeral(v).format('0.[00]') === String(v) || `小數點最多2位數`
              )
            }
          ]"
        ></v-text-field>
        <v-text-field
          v-permission-with-city="['keelung', 'changhua']"
          v-model="rate"
          label="特殊費率設定"
          :rules="[
            (v) => !!v || '必填',
            (v) => {
              return Number(v).toString() === String(v) || '格式不符'
            },
            (v) => {
              return (
                (Number(v) >= 0.1 && Number(v) <= 63.9) || '需介於 0.1 ~ 63.9'
              )
            },
            (v) => {
              return (
                numeral(v).format('0.[0]') === String(v) || `小數點最多1位數`
              )
            }
          ]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="!seveLoading ? 'primary' : 'grey'"
          @click="seve()"
          :loading="seveLoading"
          :disabled="!valid || !rate"
        >
          儲存
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardReader from '@/api/ems/CardReader'

export default {
  name: 'CardReaderRate',
  mixins: [],
  components: {},
  props: {
    value: {
      type: Object
    }
  },
  data: function () {
    return {
      item: null,
      seveLoading: false,
      rate: null,
      valid: true
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {
    // 移除使用 v-permission-without-city  v-permission-without-city 移除元件的監測
    this.$refs.form.inputs.forEach((input) => {
      if (this.$refs.form.$el[0].id !== input.$refs.input.id) {
        this.$refs.form.unregister(input)
      }
    })
  },
  destroyed() {},
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show'
    }),
    close() {
      this.$emit('close')
    },
    seve() {
      this.seveLoading = true

      CardReader.setClassCardreaderRate(
        this.value.classroomId,
        [this.value.id],
        this.rate
      )
        .then((resp) => {
          this.$emit('save', this.value)
          this.showMsg({
            message: `設定完成`,
            closable: true,
            time: 3000
          })
        })
        .catch((error) => {
          console.log(error)
          this.showMsg({
            color: 'error',
            message: `設定失敗`,
            closable: true,
            time: 3000
          })
        })
        .finally(() => {
          this.seveLoading = false
        })
    }
  }
}
</script>

<style></style>
