<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon x-small v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <CardReaderRate
        v-model="meterData"
        @save="save()"
        @close="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import CardReaderRate from '@/components/Action/CardReaderRate'

export default {
  name: 'CardReaderRateDialog',
  mixins: [],
  components: {
    CardReaderRate
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    meterData: {
      type: Object,
      require: true
    }
  },
  data: function () {
    return {
      dialog: false
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.dialog = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    save() {
      this.$emit('save', this.meterData)
      this.dialog = false
    }
  }
}
</script>

<style></style>
