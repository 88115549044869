<template>
  <v-container fluid>
    <v-row v-if="statusData.loading" dense>
      <v-col cols="12">
        <v-skeleton-loader type="text" width="200px"></v-skeleton-loader>
      </v-col>
      <v-col cols="6" md="3" v-for="item in 4" :key="item">
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
    <template v-else>
      <v-row dense>
        <v-col cols="12" class="subtitle-2 text-right ems-blue-grey-two--text">
          更新時間：{{
            statusData.data
              ? moment(statusData.data.ts).format('YYYY-MM-DD HH:mm:ss')
              : '無資料'
          }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="3">
          <v-card
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text">
                插卡狀態
              </div>
              <template v-if="statusData.data">
                <v-list-item two-line>
                  <!-- 插卡 -->
                  <v-list-item-title
                    class="text-h4 mb-1 ems-blue--text"
                    v-if="statusData.data.emsclassclasscardstatus === 1"
                  >
                    {{ showName(statusData.data.emsclasscardtype, typeOption) }}
                    <v-list-item-subtitle
                      class="caption text--secondary"
                      v-if="statusData.data.emsclassclasscardnumber"
                    >
                      {{ statusData.data.emsclassclasscardnumber }}
                    </v-list-item-subtitle>
                  </v-list-item-title>
                  <!-- 無插卡 -->
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text" v-else>
                    {{
                      showName(
                        statusData.data.emsclassclasscardstatus,
                        statusOption
                      )
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    無資料
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-content>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" v-if="statusData.data">
          <!-- 插卡 -->
          <v-card
            v-if="statusData.data.emsclassclasscardstatus === 1"
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <!-- 插卡 -->
              <!-- 計費卡 -->
              <div
                class="subtitle-2 mb-4 ems-accent-secondary--text"
                v-if="statusData.data.emsclasscardtype === 1"
              >
                卡片餘額
              </div>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text" v-else>
                使用期限
                <!-- cardtype {{statusData.data.emsclasscardtype }} 插卡狀態 status{{statusData.data.emsclassclasscardstatus }} -->
              </div>
              <!-- 無資訊 -->
              <template v-if="statusData.data.emsclasscardtype === 0">
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    <!-- {{ showName(statusData.data.emsclassclasscardstatus, statusOption) }} -->
                    {{ statusData.data.emsclassfreecardusageperiod }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <!-- 計費卡 -->
              <template v-else-if="statusData.data.emsclasscardtype === 1">
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    {{ statusData.data.emsclassbillingcardbalance }}
                  </v-list-item-title>
                  <v-list-item-subtitle>NTD</v-list-item-subtitle>
                </v-list-item>
              </template>
              <!-- 強制卡/ 免費卡 -->
              <template v-else>
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    {{ statusData.data.emsclassfreecardusageperiod }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-content>
          </v-card>
          <!-- 未插入 -->
          <v-card
            v-else
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text">狀態</div>
              <v-list-item two-line>
                <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                  {{
                    showName(
                      statusData.data.emsclassclasscardstatus,
                      statusOption
                    )
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-content>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" v-else>
          <!-- 插卡 -->
          <v-card
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text">
                卡片餘額 / 使用期限
              </div>
              <template>
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    無資料
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-content>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text">
                冷氣用電費率
                <CardReaderRateDialog
                  v-permission-without-city="['keelung', 'changhua']"
                  v-permission="['SCH_ADMIN']"
                  class="d-inline"
                  :meterData="device"
                  @save="getCardInfo()"
                />
              </div>
              <template
                v-if="
                  (statusData.data &&
                    statusData.data.emsairconditioningchargerate) ||
                  statusData.data.emsairconditioningchargerate === 0
                "
              >
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    {{ statusData.data.emsairconditioningchargerate || '--' }}
                  </v-list-item-title>
                  <v-list-item-subtitle>NTD/度</v-list-item-subtitle>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    無資料
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-content>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card
            class="mx-auto pa-3 ems-opacity-75-bg frosted-glass"
            elevation="0"
          >
            <v-list-item-content>
              <div class="subtitle-2 mb-4 ems-accent-secondary--text">
                冷氣供電狀態
              </div>
              <template v-if="statusData.data">
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    {{
                      showName(
                        statusData.data.emsclassrelaystatus,
                        relayStatusOption
                      )
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item two-line>
                  <v-list-item-title class="text-h4 mb-1 ems-blue--text">
                    無資料
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-item-content>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-3">
        <v-data-table
          :headers="table.headers"
          :items="table.data || []"
          :items-per-page="table.perPage"
          :loading="table.loading"
          loading-text="Loading..."
          no-data-text="查無資料"
          :footer-props="{
            'items-per-page-text': '每頁'
          }"
          :sort-by="['timestamp']"
          :sort-desc="[true]"
          class="elevation-0"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.ts`]="{ item }">
            {{ item.ts ? moment(item.ts).format('YYYY-MM-DD HH:mm:ss') : null }}
          </template>
          <template v-slot:[`item.emsclasscardtype`]="{ item }">
            {{ showName(item.emsclasscardtype, typeOption) }}
          </template>
          <template v-slot:[`item.emsclassclasscardstatus`]="{ item }">
            {{ showName(item.emsclassclasscardstatus, statusOption) }}
          </template>
          <template v-slot:[`item.emsclassbillingcardbalance`]="{ item }">
            {{ item.emsclassbillingcardbalance }}
          </template>
        </v-data-table>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { countdownReload } from '@/shared/utils'
import CardReader from '@/api/ems/CardReader'
import BranchSchool from '@/api/ems/BranchSchool'
import BranchClass from '@/api/ems/BranchClass'
import CardReaderRateDialog from '@/components/CardReaderRateDialog'

export default {
  components: {
    CardReaderRateDialog
  },
  props: {
    deviceId: {
      type: String,
      required: true
    },
    device: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timer: null,
      statusData: {
        loading: true,
        data: {}
      },
      table: {
        headers: [
          {
            text: '時間',
            align: 'start',
            value: 'timestamp',
            class: 'ems-pale-blue accent--text text-subtitle-2'
          },
          {
            text: '卡號',
            value: 'emsclassclasscardnumber',
            class: 'ems-pale-blue accent--text text-subtitle-2'
          },
          {
            text: '卡別',
            value: 'emsclasscardtype',
            class: 'ems-pale-blue accent--text text-subtitle-2'
          },
          {
            text: '動作',
            value: 'emsclassclasscardstatus',
            class: 'ems-pale-blue accent--text text-subtitle-2'
          },
          {
            text: '讀值',
            value: 'emsclassbillingcardbalance',
            class: 'ems-pale-blue accent--text text-subtitle-2'
          }
        ],
        data: null,
        perPage: 10,
        currentPage: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      typeOption: 'card/typeOption',
      statusOption: 'card/statusOption',
      relayStatusOption: 'ac/relayStatusOption'
    }),
    schoolId() {
      return this.pageData.parents[this.pageData.level - 1]
    }
  },
  methods: {
    showName(value, options) {
      if (!options || options.length === 0) {
        return null
      }
      const foundItem = options.find((item) => item.value === value)
      return foundItem && foundItem.name ? foundItem.name : '無資料'
    },
    getInfo() {
      this.getCardInfo()
      this.getHistory(this.deviceId)
      // this.getCardRate()
    },
    async getCardInfo() {
      if (!this.deviceId) {
        return
      }
      try {
        const { data } = await CardReader.getCard(this.deviceId)
        this.statusData.data = data || {}
      } catch (error) {
        console.error(error)
        this.statusData.data = {}
      } finally {
        this.statusData.loading = false
      }
    },
    async getHistory(id) {
      if (!id) return
      this.table.loading = true
      try {
        const { data } = await CardReader.history(id)
        this.table.data = data
      } catch (error) {
        this.table.data = null
      } finally {
        this.table.loading = false
      }
    }
  },
  async mounted() {
    this.getInfo()
    this.timer = countdownReload(this.getInfo, 15)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped></style>
